const userAvatars: string[] = [
  "/user/avatars/avatar1.png",
  "/user/avatars/avatar2.png",
  "/user/avatars/avatar3.png",
  "/user/avatars/avatar4.png",
  "/user/avatars/avatar5.png",
  "/user/avatars/avatar6.png",
  "/user/avatars/avatar7.png",
  "/user/avatars/avatar8.png",
  "/user/avatars/avatar9.png",
  "/user/avatars/avatar10.png",
  "/user/avatars/avatar11.png",
  "/user/avatars/avatar12.png",
];

export default userAvatars;
