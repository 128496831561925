const teamAvatars: string[] = [
  "/team/avatars/teamavatar1.png",
  "/team/avatars/teamavatar2.png",
  "/team/avatars/teamavatar3.png",
  "/team/avatars/teamavatar4.png",
  "/team/avatars/teamavatar5.png",
  "/team/avatars/teamavatar6.png",
  "/team/avatars/teamavatar7.png",
  "/team/avatars/teamavatar8.png",
  "/team/avatars/teamavatar9.png",
  "/team/avatars/teamavatar10.png",
  "/team/avatars/teamavatar11.png",
  "/team/avatars/teamavatar12.png",
];

export default teamAvatars;
